import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Data-seq Soluciones digitales empresariales</title>
                <meta name="description" content="Data-Seq - Tecnología a tu servicio" />
                <meta name="og:title" property="og:title" content="Data-Seq - Tecnología a tu servicio"></meta>
                <link rel="canonical" href="https://www.data-seq.com/"></link>
                <meta property="og:image" content="https://thumbs.dreamstime.com/b/evaluaci%C3%B3n-comentarios-de-servicio-al-cliente-empresario-pulsando-bot%C3%B3n-en-pantalla-210460478.jpg" />
            </Helmet>
        </div>
    )
}

export default SEO
