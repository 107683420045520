import React from 'react'

import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

const GoTop = ({scrollStepInPx, delayInMs}) => {

    const [thePosition, setThePosition] = React.useState(false);
    const timeoutRef = React.useRef(null);

    React.useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                setThePosition(true)
            } else {
                setThePosition(false);
            }
        });
    }, [])
    
    const onScrollStep = () => {
        if (window.pageYOffset === 0){
            clearInterval(timeoutRef.current);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }

    const scrollToTop = () => {
        timeoutRef.current = setInterval(onScrollStep, delayInMs);
    }

    const renderGoTopIcon = () => {
        return (
            <div 
                className={` go-top2  ${thePosition ? 'active' : ''}`} 
                onClick={scrollToTop} 
                role="button" 
                tabIndex="0"
                aria-hidden="true">
                <i className="flaticon-up"></i>
            </div>

        
        )
    } 

    const whatsapp=()=>{
        return (
            <div 
            className={`go-top  ${thePosition ? 'active' : ''}`} 
            role="button" 
            tabIndex="0"
            aria-hidden="true">

          <WhatsAppWidget phoneNumber='50379625185'
           message='¿En qué podemos ayudarte?'
           textReplyTime='En linea'
           companyName='Ventas'
           placeholder='Escribe un mensaje'
           sendButton='Enviar'/>

            </div>
             )
    }




    return (
        <React.Fragment>

            {whatsapp()}
          {renderGoTopIcon()} 

        </React.Fragment>
    )
}

export default GoTop;