import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"



const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (

        
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>Data Seq es una empresa enfocada en el desarrollo, venta y diseño de software de alto rendimiento, enfocándose en el uso de nuevas tecnologías sostenibles y escalables. </p>

                            <ul className="social-link">
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                             {/*    <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                             {/*    <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                   

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explorar</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Inicio
                                    </Link>
                                </li>
                                    <li>
                                    <Link to="/nosotros">
                                       Quiénes somos
                                    </Link>
                                </li>
                            {/*     <li>
                                    <Link to="/software-venta">
                                    Software en venta
                                    </Link>
                                </li> */}
                         {/*        <li>
                                    <Link to="/blog">
                                        Our Blog
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/contact">
                                      Contáctanos
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>




                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Softwares</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/workflow/">
                                        Sistema Workflow
                                    </Link>
                                </li>



                            {/*     <li>
                                    <Link to="/software-venta">
                                    Software en venta
                                    </Link>
                                </li> */}
                         {/*        <li>
                                    <Link to="/blog">
                                        Our Blog
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/seqgps/">
                                     Seq GPS
                                    </Link>
                                </li>
                                
                                <li>
                                    <Link to="/cobranza/">
                                    Sistema de cobranza
                                    </Link>
                                </li>


                            </ul>
                        </div>
                    </div>














                    

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Servicios</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/desarrollo-web">
                                        Desarrollo Web
                                    </Link>
                                </li>
                                              
                                <li>
                                    <Link to="/desarrollo-movil">
                                        Desarrollo Movil
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/planta-telefonica">
                                    Planta telefónica
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Dirección</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    Alameda Manuel Enrique Araujo CC Feria Rosa L 302-G San Salvador - San Salvador
                                </li>
                             {/*    <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+44587154756"></a>
                                </li> */}
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:info@data-seq.com">info@data-seq.com</a>
                                </li>
                              <li>
                                    <i className='bx bxs-inbox'></i>
                                    <a href="tel:+50325207299">(+503) 2520-7299</a>
                                </li> 
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Data-seq</strong> Reservados todos los derechos<a target="_blank" href="https://www.dataseq.com/" rel="noreferrer"></a></p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        Terms & Conditions
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;